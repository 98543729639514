import { SyntheticEvent, useEffect, useState } from "react";
import "./Person.css";
import { Link, useParams } from "react-router-dom";
import Select, { MultiValue, SingleValue } from "react-select";
import {
  AmStatusCountByPerID,
  MC,
  Pappt,
  PmStatusCountByPerID,
  Ppl,
  Prank,
  selectOpt,
} from "../model";
import { Constants } from "../Constants";
import axios, { AxiosError } from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../store/indexStore";

const Person = () => {
  const { id } = useParams();
  const d = new Date();
  const [year, setYear] = useState(d.getFullYear());
  const [edit, setEdit] = useState(false);
  // creating personel
  const [pName, setPname] = useState("");
  const [pRank, setPrank] = useState(1);
  const [pAppt, setPappt] = useState(1);
  const [pLoc, setPloc] = useState<number[]>([]);

  const yearOpts: selectOpt[] = [];
  for (let y = +d.getFullYear(); y >= year - 5; y--) {
    yearOpts.push({ value: y, label: "" + y });
  }
  const [amstates, setAmStates] = useState<AmStatusCountByPerID[]>([]);
  const [pmstates, setPmStates] = useState<PmStatusCountByPerID[]>([]);

  // loading MC, Appt and Ranks from redux
  const locations: MC[] = useSelector((state:RootState) => state.mc).loc;
  const ranks : Prank[] = useSelector((state:RootState) => state.rank);
  const appts : Pappt[] = useSelector((state:RootState) => state.appt);

  useEffect(() => {
    axios
      .get<AmStatusCountByPerID[]>(Constants.URL_PERS_AM_ATTENDANCE, {
        params: { id, year },
      })
      .then((res) => {
        setAmStates([...res.data]);
      })
      .catch((err) => console.log(err));
    axios
      .get<PmStatusCountByPerID[]>(Constants.URL_PERS_PM_ATTENDANCE, {
        params: { id, year },
      })
      .then((res) => {
        setPmStates(res.data);
      })
      .catch((err) => console.log(err));
    axios
      .get<Ppl>(Constants.URL_PERSON + id)
      .then((res) => {
        setPname(res.data.name);
        setPappt(res.data.appointment.id);
        setPrank(res.data.rank.id);
        const loc: number[] = [];
        for (let l of res.data.location) loc.push(l.id);
        setPloc(loc);
      })
      .catch((err) => console.log(err));
  }, [year, id, edit]);

  const mergeCountGrpbyStatusId: {
    statusid: number;
    state: string;
    amCount: number;
    pmCount: number;
  }[] = [];
  const set = new Set<number>();
  for (let state of amstates) set.add(state.amstatusId);
  for (let state of pmstates) set.add(state.pmstatusId);
  set.forEach((s) => {
    let amCount = amstates.filter((am) => am.amstatusId === s)[0]?.count;
    let pmCount = pmstates.filter((pm) => pm.pmstatusId === s)[0]?.count;
    let state =
      amCount > 0
        ? amstates.filter((am) => am.amstatusId === s)[0].state
        : pmstates.filter((pm) => pm.pmstatusId === s)[0].state;
    mergeCountGrpbyStatusId.push({ statusid: s, state, amCount, pmCount });
  });
  console.log(mergeCountGrpbyStatusId);

  const locationOpt: selectOpt[] = [];
  locations.forEach((e) => locationOpt.push({ value: e.id, label: e.name }));

  const rankOpt: selectOpt[] = [];
  ranks.forEach((e) => rankOpt.push({ value: e.id, label: e.rankName }));

  const apptOpt: selectOpt[] = [];
  appts.forEach((e) => apptOpt.push({ value: e.id, label: e.apptName }));

  const yearSelHandler = async (e: SingleValue<selectOpt>) => {
    const y = (e?.value as number) || d.getFullYear();
    setYear(y);
    const amResult = await axios.get<AmStatusCountByPerID[]>(
      Constants.URL_PERS_AM_ATTENDANCE,
      {
        params: { id, year: y },
      }
    );
    setAmStates(amResult.data);
    const pmResult = await axios.get<PmStatusCountByPerID[]>(
      Constants.URL_PERS_PM_ATTENDANCE,
      {
        params: { id, year: y },
      }
    );
    setPmStates(pmResult.data);
  };

  const nameEditHandler = (name: string) => {
    setPname(name);
  };

  const editHandler = async (
    e: SyntheticEvent<HTMLFormElement, SubmitEvent>
  ) => {
    e.preventDefault();
    const ppl = {
      id: id,
      apptid: pAppt,
      name: pName,
      rankid: pRank,
      locid: pLoc,
    };
    if (
      window.confirm("Are you sure that you want to edit this person detail?")
    ) {
      try {
        const result = await axios.put(Constants.URL_PERSON, ppl);
        setEdit((prev) => !prev);
        window.alert("edited");
      } catch (error:unknown) {
        if (error instanceof AxiosError) window.alert(error.response?.data)
        else if (error instanceof Error) window.alert(error.message)
        else window.alert(error);
      }
    }
  };

  const apptSelHandler = (e: SingleValue<selectOpt>) => {
    if (e?.value != undefined) setPappt(+e?.value);
  };

  const rankSelHandler = (e: SingleValue<selectOpt>) => {
    if (e?.value != undefined) setPrank(+e?.value);
  };

  const locationSelHandler = (e: MultiValue<selectOpt>) => {
    const tmp: number[] = [];
    e.forEach((el) => tmp.push(+el.value));
    setPloc(tmp);
  };

  return (
    <div className="PersContainer">
      This is persons pg for {id} {d.getFullYear()}
      <div className="selectContainer">
        <Select
          options={yearOpts}
          value={yearOpts.filter((y) => y.value === year)[0]}
          onChange={(e) => yearSelHandler(e)}
        />
      </div>
      <table className="persTable">
        <thead>
          <tr>
            <th>Status Id</th>
            <th>Status</th>
            <th>Am Count</th>
            <th>Pm Count</th>
          </tr>
        </thead>
        <tbody>
          {mergeCountGrpbyStatusId.map((s) => {
            return (
              <tr key={s.statusid}>
                <td>{s.statusid}</td>
                <td>{s.state}</td>
                <td>
                  {s.amCount > 0 ? (
                    <Link
                      to={
                        "att?year=" +
                        year +
                        "&pid=" +
                        id +
                        "&statusid=" +
                        s.statusid +
                        "&pm=0"
                      }
                    >
                      {s.amCount}
                    </Link>
                  ) : 0}
                </td>
                <td>
                  {s.pmCount > 0 ? (
                    <Link
                      to={
                        "att?year=" +
                        year +
                        "&pid=" +
                        id +
                        "&statusid=" +
                        s.statusid +
                        "&pm=1"
                      }
                    >
                      {s.pmCount}
                    </Link>
                  ) :  0}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <h3>Edit Person</h3>
      <form onSubmit={editHandler}>
        id:{id}
        <div className="selectContainer" style={{width:"150px"}}>
          <Select
            options={rankOpt}
            value={rankOpt.filter((r) => r.value === pRank)}
            onChange={rankSelHandler}
          />
        </div>
        <label htmlFor="pName" style={{ display: "block" }}>
          name
        </label>
        <input
          type="text"
          name="pName"
          id="pName"
          value={pName}
          onChange={(e) => nameEditHandler(e.target.value)}
        />
        <div className="selectContainer" style={{width:"150px"}}>
          <Select
            options={apptOpt}
            value={apptOpt.filter((a) => a.value === pAppt)}
            onChange={apptSelHandler}
          />
        </div>
        <div style={{ width: "200px" }}>
          <Select
            options={locationOpt}
            isMulti
            value={locationOpt.filter((l) => pLoc.includes(+l.value))}
            onChange={locationSelHandler}
          />
        </div>
        <br />
        <button type="submit">edit</button>
      </form>
    </div>
  );
};

export default Person;
