import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider, createHashRouter } from "react-router-dom";
import RootPg from "./page/RootPg";
import { Provider } from "react-redux";
import store from "./store/indexStore";
import LoginPg from "./page/loginPg";
import { loginAuthChecker, verification } from "./util/authHelper";
import ParadeState from "./component/paradeState";
import LogOut from "./component/Logout";
import Setting from "./component/Setting";
import DashBoard from "./component/Dashboard";
import Person from "./component/Person";
import Attlist from "./component/AttList";
import AttEdit from "./component/AttEdit";
import axios from "axios";
import { Pappt, Prank, S_Event, Status } from "./model";
import { Constants } from "./Constants";
import { statusSlice } from "./store/status";
import Leave from "./component/Leave";
import { pRankSlice } from "./store/rank";
import { pApptSlice } from "./store/appt";
import LeaveList from "./component/LeaveList";
import { holidaySlice } from "./store/holiday";
import Feedback from "./component/Feedback";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createHashRouter([
  {
    path: "/admin",
    element: <RootPg />,
    children: [
      {
        path: "",
        element: <ParadeState />,
        loader: verification,
      },
      {
        path: "feedback",
        element: <Feedback />,
        loader: verification,
      },
      {
        path: "paradestate",
        element: <ParadeState />,
        loader: verification,
      },
      {
        path: "leavelist",
        element: <LeaveList />,
        loader: verification,
      },
      {
        path: "setting",
        element: <Setting />,
        loader: verification,
      },
      {
        path: "dashboard",
        element: <DashBoard />,
        loader: verification,
      },
      {
        path: "person/:id",
        element: <Person />,
        loader: verification,
      },
      {
        path: "leave/:id",
        element: <Leave />,
        loader: verification,
      },
      {
        path: "person/:id/att",
        element: <Attlist />,
        loader: verification,
      },
      {
        path: "person/:id/att/:aid",
        element: <AttEdit />,
        loader: verification,
      },
    ],
  },
  {
    path: "/logout",
    element: <LogOut />,
  },
  {
    path: "/login",
    element: <LoginPg />,
    loader: loginAuthChecker,
  },
  {
    path: "/",
    element: <LoginPg />,
    loader: loginAuthChecker,
  },
]);

const getStatusRankApptMC = async () => {
  try {
    const result = await axios.get<Status[]>(Constants.URL_STATUSS);
    store.dispatch(statusSlice.actions.setStatus(result.data));
    const ranks = await axios.get<Prank[]>(Constants.URL_RANKS);
    store.dispatch(pRankSlice.actions.setRank(ranks.data));
    const appts = await axios.get<Pappt[]>(Constants.URL_APPTS);
    store.dispatch(pApptSlice.actions.setAppt(appts.data));
    // const mcs = await axios.get<MC[]>(Constants.URL_LOCATIONS_PUB);
    // store.dispatch(mcSlice.actions.setMC(mcs.data));
    const holiday = await axios.get<S_Event[]>(Constants.URL_HOLIDAY_PUB);
    store.dispatch(holidaySlice.actions.setHoliday(holiday.data));
  } catch (error) {
    window.alert("error loading status, ranks and appts from redux");
    console.log(error);
  }
};

getStatusRankApptMC();

root.render(
  <Provider store={store}>
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
