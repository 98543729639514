import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Prank } from "../model";

const initialState: Prank[] = [
  {id:1, rankName:"PTE"},
  {id:2, rankName:"LCP"},
  {id:3, rankName:"CPL"},
  {id:4, rankName:"CPC"},
];

export const pRankSlice = createSlice({
  name: "Prank",
  initialState,
  reducers: {
    setRank: (state: Prank[], action: PayloadAction<Prank[]>) => {
      state = action.payload;
      return state;
    },
  },
});

export default pRankSlice.reducer;
