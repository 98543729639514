import { useEffect, useState } from "react";
import { Constants } from "../Constants";
import { Attendance } from "../model";

export const round = (value: number, precision: number) => {
  let multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
};

const StrengthTable = (props: { attendances: Attendance[], subtitle:string }) => {
  const { attendances, subtitle } = props;
  const [amStrength, setAmStrength] = useState(0);
  const [pmStrength, setPmStrength] = useState(0);

  useEffect(() => {
    let am: number = 0;
    let pm: number = 0;
    for (let att of attendances) {
      if (att.amstatus === Constants.STATUS_PRESENT) am++;
      if (att.pmstatus === Constants.STATUS_PRESENT) pm++;
    }
    setAmStrength(am);
    setPmStrength(pm);
  }, [attendances]);

  return (
    <table className="copytable">
      <thead>
        <tr>
          <th>Total {subtitle}</th>
          <th>{attendances.length}</th>
          <th>%</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Am Present Strength</td>
          <td> {amStrength} </td>
          <td
            style={{
              background:
                amStrength / attendances.length >= 0.5 ? Constants.COLORABOVE50 : Constants.COLORBELOW50,
            }}
          >
            {" "}
            {round((amStrength / attendances.length) * 100, 1)}%{" "}
          </td>
        </tr>
        <tr>
          <td>Pm Present Strength</td>
          <td>{pmStrength}</td>
          <td
            style={{
              background:
                pmStrength / attendances.length >= 0.5 ? Constants.COLORABOVE50 : Constants.COLORBELOW50,
            }}
          >
            {round((pmStrength / attendances.length) * 100, 1)}%
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default StrengthTable;
