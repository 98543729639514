import { LocOpt, MC} from "../model";
import Select, { MultiValue } from "react-select";

const locOpt = (locs: MC[]) => {
  const opt: LocOpt[] = [];
  for (let l of locs) opt.push({ value: l, label: l.name });
  return opt;
};

const DboardSelectLoc = (props: {
  locations: MC[];
  selLoc: MC[];
  setSelLoc: React.Dispatch<React.SetStateAction<MC[]>>;
}) => {
  const { locations, selLoc, setSelLoc } = props;
  const locOptions = locOpt(locations);

  const onChangeLocSelect = (e: MultiValue<LocOpt>) => {
    const locs: MC[] = [];
    for (let opt of e) locs.push(opt.value);
    locs.sort((m1, m2) => m1.name.localeCompare(m2.name));
    setSelLoc(locs);
  };

  return (
    <div style={{ width: "10vw", minWidth: "300px" }}>
      <Select
        options={locOptions}
        // defaultValue={locOptions.filter((x) => x.value.id === defLoc.id)}
        value={locOpt(selLoc)}
        onChange={onChangeLocSelect}
        isMulti
      />
      <button type="button" onClick={() => setSelLoc(locations)}>
        Select All Locations
      </button>
    </div>
  );
};

export default DboardSelectLoc;