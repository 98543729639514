import axios, { AxiosError } from "axios";
import { S_Event } from "../model";
import { Constants } from "../Constants";

const SettingEventList = (props: {
  events: S_Event[];
  setEvents: (e: S_Event[]) => void;
}) => {
  const { events, setEvents } = props;

  const delHandler = async (id: number) => {
    if (window.confirm("are you sure you want to delete?")) {
      try {
        await axios.delete(Constants.URL_EVENT, { data: { id } });
        const res = await axios.get(Constants.URL_EVENT_ALL);
        setEvents(res.data);
        window.alert("deleted");
      } catch (error:unknown) {
        if (error instanceof AxiosError) window.alert(error.response?.data)
          else if (error instanceof Error) window.alert(error.message)
          else window.alert(error);
      }
    }
  };

  return (
    <>
      <table className="alluser">
        <thead>
          <tr>
            <th>Name of Event</th>
            <th>Date</th>
            <th>Delete?</th>
          </tr>
        </thead>
        <tbody>
          {events.map((e) => {
            return (
              <tr key={e.id}>
                <td>{e.name}</td>
                <td>{e.date}</td>
                <td>
                  <button onClick={() => delHandler(e.id)}>delete</button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default SettingEventList;
