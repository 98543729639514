import React, { SyntheticEvent, useState } from "react";
import Select, { MultiValue, SingleValue } from "react-select";
import { Ppl, selectOpt } from "../model";
import axios, { AxiosError } from "axios";
import { Constants } from "../Constants";

const SettingCreatePersonnel = (props: {
  setPers: (ppl: Ppl[]) => void;
  locationOpt: selectOpt[];
  apptOpt: selectOpt[];
  rankOpt: selectOpt[];
}) => {
  const [pName, setPname] = useState("");
  const [pRank, setPrank] = useState(1);
  const [pAppt, setPappt] = useState(1);
  const [pLoc, setPloc] = useState<number[]>([]);

  const rankSelectHandler = (e: SingleValue<selectOpt>) => {
    if (e?.value != undefined) setPrank(+e?.value);
  };

  const apptSelectHandler = (e: SingleValue<selectOpt>) => {
    if (e?.value != undefined) setPappt(+e?.value);
  };

  const locationSelectHandler = (e: MultiValue<selectOpt>) => {
    const tmp: number[] = [];
    e.forEach((el) => tmp.push(+el.value));
    setPloc(tmp);
  };

  const { setPers, locationOpt, rankOpt, apptOpt } = props;
  const createPersonHandler = async (
    e: SyntheticEvent<HTMLFormElement, SubmitEvent>
  ) => {
    e.preventDefault();
    try {
      const res = await axios.post(Constants.URL_PERSON, {
        name: pName,
        rank: pRank,
        appointment: pAppt,
        location: pLoc,
      });
      axios.get<Ppl[]>(Constants.URL_PERSON).then((res) => {
        setPers(res.data);
      });
      alert(res.data.result);
    } catch (error:unknown) {
      if (error instanceof AxiosError) window.alert(error.response?.data)
      else if (error instanceof Error) window.alert(error.message)
      else window.alert(error);
    }
  };

  return (
    <>
      <h3>Create Personnel</h3>
      <form onSubmit={createPersonHandler}>
        <div className="selectContainer" style={{width:"150px"}}>
          <Select options={rankOpt} onChange={rankSelectHandler} />
        </div>
        <label htmlFor="pName" style={{ display: "block" }}>
          Name
        </label>
        <input
          type="text"
          id="pName"
          name="pName"
          onChange={(e) => setPname(e.target.value)}
          value={pName}
        />
        <div className="selectContainer" style={{width:"150px"}}>
          <Select options={apptOpt} onChange={apptSelectHandler} />
          <Select
            options={locationOpt}
            onChange={locationSelectHandler}
            isMulti
          />
        </div>

        <br />
        <button type="submit">create</button>
      </form>
    </>
  );
};

export default SettingCreatePersonnel;
