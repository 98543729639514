import { useState } from "react";
import { Constants } from "../Constants";
import { GetAttByPidSidYr, Pappt } from "../model";
import { useSelector } from "react-redux";
import { RootState } from "../store/indexStore";

const Apptdd = (props: {
  choices: Pappt[];
  id: string;
  setChoice: (choice: number) => void;
}) => {
  const { choices, id, setChoice } = props;

  return (
    <select name={id} id={id} onChange={(e) => setChoice(+e.target.value || 0)}>
      {choices.map((c) => {
        return <option value={c.id} key={c.id}>{c.apptName}</option>;
      })}
    </select>
  );
};

const AttTable = (props: { attlist: GetAttByPidSidYr[], max : number }) => {
  const { attlist, max } = props;
  const [choice, SetChoice] = useState(0);
  const appts = useSelector((state: RootState) => state.appt);
  const choices: Pappt[] = [{ id: 0, apptName: "All" }, ...appts];

  const leavesAtt = attlist.filter(
    (a) =>
      a.amstatus.id === Constants.STATUS_LEAVEAL ||
      a.amstatus.id === Constants.STATUS_LEAVEOL ||
      a.pmstatus.id === Constants.STATUS_LEAVEAL ||
      a.pmstatus.id === Constants.STATUS_LEAVEOL
  );

  const filteredleavesAtt = leavesAtt.filter( a => {
    if (choice === 0) return true;
    return a.personnel.appointment.id === choice;
  })

  const cautionArray:number[] = [];
  const filteredDOLeave = filteredleavesAtt.filter( a => a.personnel.appointment.apptName === Constants.DENTALOFFICER);
  const mapDOLeave:Map<string,Set<number>> = new Map();
  for (let f of filteredDOLeave){
    if (mapDOLeave.has(f.date)) mapDOLeave.get(f.date)?.add(f.id);
    else mapDOLeave.set(f.date, new Set([f.id]));
  }
  // console.log(mapDOLeave)
  const colorCodedDO = (limit:number, a:GetAttByPidSidYr) : boolean=>{
    if (a.personnel.appointment.apptName === Constants.DENTALOFFICER && 
      mapDOLeave.has(a.date) && 
      (mapDOLeave.get(a.date)?.size || 0) > limit){
        return true;
    }
    return false;
  }


  return (
    <table className="leaveTable">
      <thead>
        <tr>
          <th>Att id</th>
          <th>id</th>
          <th>Date</th>
          <th>
            Appt: <Apptdd choices={choices} id="choice" setChoice={SetChoice}/>
          </th>
          <th>Rank</th>
          <th>Name</th>
          <th>Am Status</th>
          <th>Pm Status</th>
          <th>Remark</th>
          <th>Submitted by</th>
        </tr>
      </thead>
      <tbody>
        {filteredleavesAtt.map((a) => {
          return (
            <tr key={a.id} style={{background : colorCodedDO(max,a)?Constants.COLORBELOW50:""}}>
              <td>{a.id}</td>
              <td>{a.personnel.id}</td>
              <td>{a.date}</td>
              <td>{a.personnel.appointment.apptName}</td>
              <td>{a.personnel.rank.rankName}</td>
              <td>{a.personnel.name}</td>
              <td>{a.amstatus.state}</td>
              <td>{a.pmstatus.state}</td>
              <td>{a.remark}</td>
              <td>{a.submittedby}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default AttTable;
