export class Constants {
  static readonly URL_ADMINVERIFY = "/api/adm/verification";
  static readonly URL_ADMINS = "/api/adm/all";
  static readonly URL_LOCATION = "/api/location";
  static readonly URL_LOCATIONS = "/api/location/all";
  static readonly URL_ROLES = "/api/adm/roles";
  static readonly URL_ADMIN = "/api/adm/admin";
  static readonly URL_ADMIN_PW = "/api/adm/pw";
  static readonly URL_ADMINLOGIN = "/api/login";
  static readonly URL_PERSON = "/api/person/";
  static readonly URL_PERSON_IN_MC = "/api/person/location"
  static readonly URL_ATTENDANCE = "/api/attendence/"
  static readonly URL_LEAVE = "/api/attendence/leave/"
  static readonly URL_FEEDBACK = "/api/feedback/"
  static readonly URL_ATTENDANCE_ALL = "/api/attendence/all"
  static readonly URL_PERS_AM_ATTENDANCE = "/api/person/amattendence"
  static readonly URL_PERS_PM_ATTENDANCE = "/api/person/pmattendence"
  static readonly URL_ATT_PERS = "/api/attendence/person"
  static readonly URL_EVENT = "/api/event/"
  static readonly URL_EVENT_ALL = "/api/event/all"
  static readonly URL_STATUSS = "/pubapi/status/all"
  static readonly URL_RANKS = "/pubapi/rank/all";
  static readonly URL_APPTS = "/pubapi/appt/all";
  static readonly URL_LOCATIONS_PUB = "/pubapi/mc/all";
  static readonly URL_HOLIDAY_PUB = "/pubapi/holiday/all";
  static readonly URL_HOLIDAY = "/api/holiday";

  static readonly STATUS_PRESENT = 1;
  static readonly STATUS_LEAVEAL = 2;
  static readonly STATUS_LEAVEOL = 3;
  static readonly STATUS_MC = 4;
  static readonly STATUS_MA = 5;
  static readonly STATUS_HL = 6;
  static readonly ADMIN_SU = "super_admin";
  static readonly DENTALOFFICERID = 2;
  static readonly DENTALMGRID = 3;
  static readonly DENTALASSTID = 1;
  static readonly DENTALASAID = 4;
  static readonly DENTALOFFICER = "Dental Officer";
  static readonly DENTALMGR = "Dental Manager";
  static readonly DENTALASST = "Dental Asst";
  static readonly DENTALASA = "ASA";
  static readonly EASTLIMIT = 2;
  static readonly WESTLIMIT = 3;
  static readonly LEAVE_LMT = 10;

  static readonly COLORABOVE50 = "LightGreen"
  static readonly COLORBELOW50 = "LightCoral"

  static readonly apptRankingMap:Map<number,number> = new Map([
    [Constants.DENTALOFFICERID,1],
    [Constants.DENTALMGRID,2],
    [Constants.DENTALASSTID,3],
    [Constants.DENTALASAID,4],
  ]);

  static readonly apptNameRankingMap:Map<string,number> = new Map([
    [Constants.DENTALOFFICER,1],
    [Constants.DENTALMGR,2],
    [Constants.DENTALASST,3],
    [Constants.DENTALASA,4],
  ]);
}
