import { useSelector } from "react-redux";
import { Attendance, Person, Status, selectOpt } from "../model";
import { RootState } from "../store/indexStore";
import { Constants } from "../Constants";

const ParadeStateCopyTable = (props:{
  d: Date, 
  attendances : Attendance[],
  persons : Person[]
}) => {
  const {d ,attendances, persons} = props
  const states: Status[] = useSelector((state: RootState) => state.status);
  const statuss:selectOpt[] = [];
  for (let s of states) {statuss.push({ value: +s.id, label: s.state });}

  return (
    <>
      Date : {d.toDateString()}
      <table className="copytable">
        <thead>
          <tr>
            <th>Appointment</th>
            <th>Rank & Name</th>
            <th>Am Status</th>
            <th>Pm Status</th>
            <th>Remark</th>
          </tr>
        </thead>
        <tbody>
          {attendances
          .toSorted((m1,m2) =>
            (Constants.apptNameRankingMap.get(persons.filter(x=> x.id === m1.personnel)[0].apptName) ?? 0) - 
            (Constants.apptNameRankingMap.get(persons.filter(x=> x.id === m2.personnel)[0].apptName) ?? 0)
          )
          .map((a) => {
            if (a.attid) {
              let pers = persons.filter((p) => p.id === a.personnel)[0];
              return (
                <tr key={pers.id}>
                  <td>{pers.apptName}</td>
                  <td>
                    {pers.rankName} {pers.name}
                  </td>
                  <td>
                    {statuss.filter((s) => s.value === a.amstatus)[0].label}
                  </td>
                  <td>
                    {statuss.filter((s) => s.value === a.pmstatus)[0].label}
                  </td>
                  <td>{a.remark}</td>
                </tr>
              );
            }
            return <></>
          })}
        </tbody>
      </table>
    </>
  );
};

export default ParadeStateCopyTable;
