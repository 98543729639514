import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Pappt } from "../model";

const initialState: Pappt[] = [
  {id:1, apptName:"Dental Asst"},
  {id:2, apptName:"Dental Officer"},
  {id:3, apptName:"Dental Manager"},
];

export const pApptSlice = createSlice({
  name: "Pappt",
  initialState,
  reducers: {
    setAppt: (state: Pappt[], action: PayloadAction<Pappt[]>) => {
      state = action.payload;
      return state;
    },
  },
});

export default pApptSlice.reducer;
