import { useEffect, useState } from "react";
import { Constants } from "../Constants";
import axios from "axios";
import { GetAttByPidSidYr, MC, PplGetResult } from "../model";
import DashBoardWeeklyAttList from "./DashBoardWeeklyAttList";
import { useSelector } from "react-redux";
import store, { RootState } from "../store/indexStore";
import DatePicker, { DateObject } from "react-multi-date-picker";
import { Root } from "react-dom/client";

// map of week number with value of starting date and ending date
// week to start from monday to friday
export const getWeekMap = (): Map<number, Date[]> => {
  const today = new Date();
  const firstMonofYear = new Date(`${today.getFullYear()}-01-01`);
  const map: Map<number, Date[]> = new Map();
  while (firstMonofYear.getDay() != 1) {
    firstMonofYear.setDate(firstMonofYear.getDate() + 1);
  }
  const tmp = new Date(
    `${firstMonofYear.getFullYear()}-01-${firstMonofYear.getDate()}`
  );
  const tmp2 = new Date(
    `${firstMonofYear.getFullYear()}-01-${firstMonofYear.getDate() + 4}`
  );
  map.set(0, [
    firstMonofYear,
    new Date(`${tmp2.getFullYear()}-01-${tmp2.getDate()}`),
  ]);
  for (let i = 1; i < 82; i++) {
    tmp.setDate(tmp.getDate() + 7);
    tmp2.setDate(tmp2.getDate() + 7);
    map.set(i, [
      new Date(`${tmp.getFullYear()}-${tmp.getMonth() + 1}-${tmp.getDate()}`),
      new Date(
        `${tmp2.getFullYear()}-${tmp2.getMonth() + 1}-${tmp2.getDate()}`
      ),
    ]);
  }
  return map;
};

export const getWeekNo = (
  map: Map<number, Date[]>, 
  d: Date
) =>{
  const today = new Date(
    `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`
  );
  let tmp = 0;
  for (let [k, v] of map) {
    if (map.get(k)![0] <= today && map.get(k)![1] >= today) tmp = k;
  }
  return tmp;
}

export const getToweekno = (
  getWeekMap: () => Map<number, Date[]>,
  getWeekNo : (map: Map<number, Date[]>, d: Date) => number,
) => (d : Date) =>{
  return getWeekNo(getWeekMap(), d);
}

const DashBoardWeekly = (props: { 
  pplinmc: Map<number, PplGetResult[]>,
  daysfunc : React.Dispatch<React.SetStateAction<string>>,
  tabfunc : React.Dispatch<React.SetStateAction<number>>
}) => {
  const { pplinmc, daysfunc, tabfunc } = props;
  const map = getWeekMap();
  const d = new Date();
  const today = new Date(
    `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`
  );
  const tmp = getToweekno(getWeekMap, getWeekNo)(d);
  const [date, setDate] = useState<DateObject>(new DateObject());
  const [w, setW] = useState(tmp);
  const locations: MC[] = useSelector((state: RootState) => state.mc).loc;
  const holidays = useSelector((state:RootState) => state.holiday);

  const [attW, setAttW] = useState<GetAttByPidSidYr[]>([]);
  const currentWeek = () => {
    for (let [k, v] of map) {
      if (map.get(k)![0] <= today && map.get(k)![1] >= today) {
        setW(k);
        return k;
      }
    }
    return 0;
  };

  const getWeeklyAtt = (start: Date, end: Date) => {
    const startDate = `${start.getFullYear()}-${
      start.getMonth() + 1
    }-${start.getDate()}`;
    const endDate = `${end.getFullYear()}-${
      end.getMonth() + 1
    }-${end.getDate()}`;
    axios
      .get(Constants.URL_ATTENDANCE_ALL, {
        params: { date: startDate, end: endDate },
      })
      .then((res) => {
        setAttW(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    let a = currentWeek();
    getWeeklyAtt(map.get(a)![0], map.get(a)![1]);
  }, []);

  const selOnChangeHandler = (week: number) => {
    setW(week);
    getWeeklyAtt(map.get(week)![0], map.get(week)![1]);
    setDate( new DateObject((getWeekMap().get(week) ?? [])[0] || date) );
  };

  const curWeekClickHandler = () =>{
    const a = currentWeek();
    getWeeklyAtt(map.get(a)![0], map.get(a)![1]);
    setDate( new DateObject((getWeekMap().get(a) ?? [])[0] || date) );
  }

  const dataFunc = (date: DateObject) => {
    let isWeekend = [0, 6].includes(date.weekDay.index);
    if (isWeekend)
      return {
        disabled: true,
        style: { color: "#ccc" },
        onClick: () => alert("weekends are disabled."),
      };
    let holi = holidays.filter((h) => h.date === date.format("YYYY-MM-DD"));
    if (holi.length > 0)
      return {
        disabled: true,
        style: { color: "#ccc" },
        title: holi[0].name,
      };
  };

  const onChangeCalender = (e: DateObject | null) =>{
    setDate(e || date)
    let tmpdate = e || date;
    let tmpd = new Date(tmpdate.format("YYYY-MM-DD"));
    let weekno = getToweekno(getWeekMap, getWeekNo)(tmpd)
    setW(weekno);
    getWeeklyAtt(map.get(weekno)![0], map.get(weekno)![1]);
  }

  return (
    <>
      <DatePicker 
        value={date}
        onChange={(e) => onChangeCalender(e)}
        weekStartDayIndex={1}
        mapDays={({ date }) => dataFunc(date)}
      />
      <select
        name="weekly"
        id="weekly"
        value={w}
        onChange={(e) => selOnChangeHandler(+e.target.value)}
      >
        {[...map.keys()].map((k) => (
          <option key={k} value={k}>
            week {k}
          </option>
        ))}
      </select>
      <button onClick={curWeekClickHandler}>Current week</button>
      <h3>
        {map.get(w)![0].toDateString()} to {map.get(w)![1].toDateString()}
      </h3>
      {/* <DashBoardWeeklyAttList
        today={today}
        loc={1}
        pplinmc={pplinmc}
        attW={Attw}
        range={map.get(w) || [new Date("2024-01-01"), new Date("2024-01-02")]}
      /> */}
      {locations.map( l => {return ( <>
      <h3>{l.name}</h3>
      <DashBoardWeeklyAttList
        today={today}
        loc={l.id}
        pplinmc={pplinmc}
        attW={attW}
        range={map.get(w) || [new Date("2024-01-01"), new Date("2024-01-02")]}
        daysfunc= {daysfunc} 
        tabfunc={tabfunc}
      />
       </> )})}
    </>
  );
};

export default DashBoardWeekly;
