import { Link } from "react-router-dom";
import { Attendance, GetAttByPidSidYr, MC, PplGetResult } from "../model";
import StrengthTable from "./StrengthTable";
import { Constants } from "../Constants";

const DashBoardCenterAttList = (props: {
  d: Date;
  loc: MC;
  pplinmc: Map<number, PplGetResult[]>;
  attD: GetAttByPidSidYr[];
}) => {
  const { d, loc, pplinmc, attD } = props;
  const today = new Date();
  const datetoday = new Date(today.toDateString());
  const convertPast = (attD: GetAttByPidSidYr[]): Attendance[] => {
    const att: Attendance[] = [];
    for (let a of attD.filter((b) => b.location.id === loc.id)) {
      att.push({
        date: a.date,
        personnel: a.personnel.id,
        amstatus: a.amstatus.id,
        pmstatus: a.pmstatus.id,
        remark: a.remark,
        locid: a.location.id,
        attid: a.id,
        submittedby: a.submittedby,
      });
    }
    return att;
  };

  const convertProjected = (
    ppls: PplGetResult[],
    attD: GetAttByPidSidYr[],
    locid: number
  ): Attendance[] => {
    const att: Attendance[] = convertPast(
      attD.filter((b) => b.location.id === locid)
    );
    const taken = new Set<number>();
    for (let a of att) taken.add(a.personnel);
    for (let p of ppls) {
      if (taken.has(p.id)) continue;
      att.push({
        date: "",
        personnel: p.id,
        amstatus: Constants.STATUS_PRESENT,
        pmstatus: Constants.STATUS_PRESENT,
        remark: "",
        locid: locid,
        attid: -1,
        submittedby: "",
      });
    }
    return att;
  };

  const filterAmAttState = (
    loc: number,
    pid: number,
    attD: GetAttByPidSidYr[]
  ) => {
    if (attD.length > 0) {
      for (let a of attD) {
        if (a && a["location"].id === loc && pid === a.personnel.id)
          return a.amstatus.state;
      }
    }
    return "unsubmitted";
  };

  const filterPmAttState = (
    loc: number,
    pid: number,
    attD: GetAttByPidSidYr[]
  ) => {
    if (attD.length > 0) {
      for (let a of attD) {
        if (a && a["location"].id === loc && pid === a.personnel.id)
          return a.pmstatus.state;
      }
    }
    return "unsubmitted";
  };

  const filterAttRmk = (loc: number, pid: number, attD: GetAttByPidSidYr[]) => {
    if (attD.length > 0) {
      for (let a of attD) {
        if (a && a["location"].id === loc && pid === a.personnel.id)
          return a.remark;
      }
    }
    return "unsubmitted";
  };

  const filterAttId = (loc: number, pid: number, attD: GetAttByPidSidYr[]) => {
    if (attD.length > 0) {
      for (let a of attD) {
        if (a && a["location"].id === loc && pid === a.personnel.id)
          return a.id;
      }
    }
    return "unsubmitted";
  };

  return (
    <section key={loc.id}>
      <b>{loc.name}</b>
      <table className="dBoardTable">
        <thead>
          <tr>
            {/* <th>Att id</th>
            <th>id</th> */}
            <th>Appt</th>
            <th>Rank</th>
            <th>Name</th>
            <th>Am Status</th>
            <th>Pm Status</th>
            <th>Remark</th>
          </tr>
        </thead>
        <tbody>
          {/* looking at today or future projection */}
          {datetoday <= d &&
            pplinmc.has(loc.id) &&
            pplinmc
              .get(loc.id)!
              .toSorted((m1, m2) => {
                if (m1.appointment.id === m2.appointment.id)
                  return m2.rank.id - m1.rank.id;
                return (
                  (Constants.apptRankingMap.get(m1.appointment.id) ?? 0) -
                  (Constants.apptRankingMap?.get(m2.appointment.id) ?? 0)
                );
              })
              .map((pers) => {
                return (
                  <tr key={pers.id + " " + loc.id}>
                    {/* <td>{filterAttId(loc.id, pers.id, attD)}</td>
                  <td>{pers.id}</td> */}
                    <td>{pers.appointment.apptName}</td>
                    <td>{pers.rank.rankName}</td>
                    <td>
                      <Link to={"/admin/person/" + pers.id}>{pers.name}</Link>
                    </td>
                    <td>{filterAmAttState(loc.id, pers.id, attD)}</td>
                    <td>{filterPmAttState(loc.id, pers.id, attD)}</td>
                    <td>{filterAttRmk(loc.id, pers.id, attD)}</td>
                  </tr>
                );
              })}
          {/* looking at past attendence */}
          {datetoday > d &&
            attD
              .filter((att) => att.location.id === loc.id)
              .toSorted((m1, m2) => {
                if (m1.personnel.appointment.id === m2.personnel.appointment.id)
                  return m2.personnel.rank.id - m1.personnel.rank.id;
                return (
                  (Constants.apptRankingMap.get(m1.personnel.appointment.id) ??
                    0) -
                  (Constants.apptRankingMap?.get(m2.personnel.appointment.id) ??
                    0)
                );
              })
              .map((attp) => {
                return (
                  <tr key={attp.id + " " + loc.id}>
                    <td>{attp.personnel.appointment.apptName}</td>
                    <td>{attp.personnel.rank.rankName}</td>
                    <td>
                      <Link to={"/admin/person/" + attp.personnel.id}>
                        {attp.personnel.name}
                      </Link>
                    </td>
                    <td>{attp.amstatus.state}</td>
                    <td>{attp.pmstatus.state}</td>
                    <td>{attp.remark}</td>
                  </tr>
                );
              })}
        </tbody>
      </table>
      <br />
      {datetoday > d && (
        <div className="strengthtable">
          <StrengthTable attendances={convertPast(attD)} subtitle="Personnel"/>
          <StrengthTable
            attendances={convertPast(
              attD.filter(
                (dr) =>
                  dr.personnel.appointment.id === Constants.DENTALOFFICERID
              )
            )}
            subtitle="Dentist"
          />
        </div>
      )}
      {datetoday <= d && pplinmc.has(loc.id) && (
        <div className="strengthtable">
          <StrengthTable
            attendances={convertProjected(
              pplinmc.get(loc.id) as PplGetResult[],
              attD,
              loc.id
            )}
            subtitle="Personnel"
          />
          <StrengthTable
            attendances={convertProjected(
              pplinmc.get(loc.id)?.filter(dr => dr.appointment.id === Constants.DENTALOFFICERID) as PplGetResult[],
              attD.filter(dr=> dr.personnel.appointment.id === Constants.DENTALOFFICERID),
              loc.id
            )}
            subtitle="Dentist"
          />
        </div>
      )}
      <br />
    </section>
  );
};

export default DashBoardCenterAttList;
