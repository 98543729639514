import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { S_Event } from "../model";

const initialState: S_Event[] = [];

export const holidaySlice = createSlice({
  name: "holiday",
  initialState,
  reducers: {
    setHoliday: (state: S_Event[], action: PayloadAction<S_Event[]>) => {
      state = action.payload;
      return state;
    },
  },
});

export default holidaySlice.reducer;
