import axios from "axios";
import { redirect } from "react-router-dom";
import { Constants } from "../Constants";
import store from "../store/indexStore";
import { IVerificationResult, MC } from "../model";
import { adminSlice } from "../store/admin";
import mc, { mcSlice } from "../store/mc";


// check for jwt
export const verification = async () => {
  const token = localStorage.getItem("psau");
  if (!token) return redirect("/");

  try {
    const response = await axios.post<IVerificationResult>(
      Constants.URL_ADMINVERIFY,
      {
        token,
      }
    );
    const { username, role, id, location, locid } = response.data;
    store.dispatch(
      adminSlice.actions.setToken({ username, role, id, isLoggedIn: true, location, locid })
    );
    if (!store.getState().mc.loaded){
      const mcs = await axios.get<MC[]>(Constants.URL_LOCATIONS);
      store.dispatch(mcSlice.actions.setMC(mcs.data));
      store.dispatch(mcSlice.actions.setLoaded(true));
    }
    return null;
  } catch (error) {
    //401 unauthorised
    localStorage.removeItem("psau");
    store.dispatch(adminSlice.actions.setToken({ isLoggedIn: false }));
    console.log("invalid token")
    return redirect("/login");
  }
};


// loader
export const loginAuthChecker = async()=>{
  const token = localStorage.getItem("psau");
  if (!token) return null;

  try {
    const response = await axios.post<{ result: IVerificationResult }>(
      Constants.URL_ADMINVERIFY,
      {
        token,
      }
    );
    const { username, role, id, location, locid } = response.data.result;
    store.dispatch(
      adminSlice.actions.setToken({ username, role, id, isLoggedIn: true, location, locid })
    );
    if (!store.getState().mc.loaded){
      const mcs = await axios.get<MC[]>(Constants.URL_LOCATIONS);
      store.dispatch(mcSlice.actions.setMC(mcs.data));
      store.dispatch(mcSlice.actions.setLoaded(true));
    }
    return redirect('/admin');
  } catch (error) {
    //401 unauthorised
    localStorage.removeItem("psau");
    store.dispatch(adminSlice.actions.setToken({ isLoggedIn: false }));
    return null;
  }
}