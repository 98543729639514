import { configureStore } from "@reduxjs/toolkit";
import adminReducer from "./admin";
import statusReducer from "./status";
import pRankReducer from "./rank";
import pApptReducer from "./appt";
import mcReducer from "./mc";
import holidayReducer from "./holiday"

const store = configureStore({
  reducer: {
    admin: adminReducer,
    status: statusReducer,
    rank: pRankReducer,
    appt: pApptReducer,
    mc: mcReducer,
    holiday: holidayReducer,
  },
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
