import { useEffect, useState } from "react";
import { GetAttByPidSidYr, MC, PplGetResult, TokenAuth } from "../model";
import { useSelector } from "react-redux";
import { RootState } from "../store/indexStore";
import axios from "axios";
import { Constants } from "../Constants";
import DashBoardMonthlyList from "./DashBoardMonthlyList";
import DboardSelectLoc from "./DboardSelectLoc";

const getMthMap = (year: number) => {
  const res: Map<number, Date[]> = new Map();
  for (let i = 0; i < 12; i++) {
    res.set(i, [new Date(year, i, 1), new Date(year, i + 1, 0)]);
  }
  for (let i = 0; i < 6; i++) {
    res.set(i + 12, [new Date(year + 1, i, 1), new Date(year + 1, i + 1, 0)]);
  }
  return res;
};

const DashBoardMonthly = (props: { 
  pplinmc: Map<number, PplGetResult[]>,
  daysfunc: React.Dispatch<React.SetStateAction<string>>,
  tabfunc: React.Dispatch<React.SetStateAction<number>>
}) => {
  const { pplinmc, daysfunc, tabfunc } = props;
  const today = new Date();
  const [mth, setMth] = useState(today.getMonth());
  const thisyear = today.getFullYear();
  const mthMap = getMthMap(thisyear);
  const mthArr = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const locations: MC[] = useSelector((state: RootState) => state.mc).loc;
  const admininfo: TokenAuth = useSelector((state: RootState) => state.admin);
  const defLoc: MC = {
    name: admininfo.location ?? "",
    id: admininfo.locid ?? 0,
  };
  const [selLoc, setSelLoc] = useState<MC[]>([defLoc]);

  const [attM, setAttM] = useState<GetAttByPidSidYr[]>([]);

  const getMthlyAtt = (start: Date, end: Date) => {
    const startDate = `${start.getFullYear()}-${
      start.getMonth() + 1
    }-${start.getDate()}`;
    const endDate = `${end.getFullYear()}-${
      end.getMonth() + 1
    }-${end.getDate()}`;
    axios
      .get(Constants.URL_ATTENDANCE_ALL, {
        params: { date: startDate, end: endDate },
      })
      .then((res) => {
        setAttM(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getMthlyAtt(
      mthMap.get(today.getMonth())![0],
      mthMap.get(today.getMonth())![1]
    );
  }, []);

  const onSelectHandler = (val: number) => {
    setMth(val);
    getMthlyAtt(mthMap.get(val)![0], mthMap.get(val)![1]);
  };

  return (
    <>
      <select
        name="mthly"
        id="mthly"
        value={mth}
        onChange={(e) => onSelectHandler(+e.target.value)}
      >
        {[...mthMap.keys()].map((k) => (
          <option key={k} value={k}>
            {mthArr[k % 12]} {(mthMap.get(k) || [today])[0].getFullYear()}
          </option>
        ))}
      </select>
      <h3>
        {mthMap.get(mth)![0].toDateString()} to{" "}
        {mthMap.get(mth)![1].toDateString()}
      </h3>
      <DboardSelectLoc locations={locations} selLoc={selLoc} setSelLoc={setSelLoc}/>
      {selLoc.map((l) => {
        return (
          <>
            <h3>{l.name}</h3>
            <DashBoardMonthlyList
              today={today}
              loc={l.id}
              pplinmc={pplinmc}
              attM={attM}
              range={[mthMap.get(mth)![0], mthMap.get(mth)![1]]}
              month={mth}
              daysfunc={daysfunc}
              tabfunc={tabfunc}
            />
          </>
        );
      })}
    </>
  );
};

export default DashBoardMonthly;
