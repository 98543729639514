import { Calendar } from "react-multi-date-picker";
import { S_Event } from "../model";
import { useSelector } from "react-redux";
import { RootState } from "../store/indexStore";

const ParadeState_calender = (props: { events: S_Event[] }) => {
  const { events } = props;
  const d = new Date();
  const today = new Date(d.toDateString());
  const holidays = useSelector((state:RootState)=>state.holiday);

  return (
    <div className="calendercontainer">
      <Calendar
        weekStartDayIndex={1}
        mapDays={({ date, isSameDate }) => {
          let isWeekend = [0, 6].includes(date.weekDay.index);
          if (isWeekend)
            return {
              disabled: true,
              style: { color: "#ccc" },
            };
          let holi = holidays.filter(h => h.date === date.format("YYYY-MM-DD"))
            if (holi.length > 0)
            return {
              disabled: true,
              style: { color: "#ccc" },
              title: holi[0].name,
          }
          let evt = events.filter((e) => e.date === date.format("YYYY-MM-DD"));
          if (evt.length > 0)
            return {
              style: {
                borderRadius: "3px",
                color: "#FF0000",
                backgroundColor: "#CCC",
              },
              title: evt[0].name,
            };
        }}
      />
      <div>
        <table className="copytable">
          <thead>
            <tr>
              <th>Date</th>
              <th>Event</th>
            </tr>
          </thead>
          <tbody>
            {events.map((e) => {
              let edate = new Date(e.date);
              if (edate >= today)
                return (
                  <tr key={e.id}>
                    <td>{e.date}</td>
                    <td>{e.name}</td>
                  </tr>
                );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ParadeState_calender;
