import { Link } from "react-router-dom";
import { Attendance, Person, Status, selectOpt } from "../model";
import { useSelector } from "react-redux";
import { RootState } from "../store/indexStore";
import { Constants } from "../Constants";

const ParadeStateSubmitted = (props:{
  attendances : Attendance[],
  persons : Person[]
})=>{
  const states: Status[] = useSelector((state: RootState) => state.status);
  const statuss: selectOpt[] = [];
  for (let s of states) statuss.push({ value: +s.id, label: s.state });
  const {attendances, persons} = props;

  return (
    <>
      <h3>Submitted Parade State</h3>
      <table className="PsTable">
        <thead>
          <tr>
            {/* <th>Attendance Id</th>
            <th>Id</th> */}
            <th>Appt</th>
            <th>Rank</th>
            <th>Name</th>
            <th>am Status</th>
            <th>pm Status</th>
            <th>Remark</th>
            <th>Leave</th>
            <th>SubmittedBy</th>
          </tr>
        </thead>
        <tbody>
          {attendances
          .toSorted((m1,m2) =>
            (Constants.apptNameRankingMap.get(persons.filter(x=> x.id === m1.personnel)[0].apptName) ?? 0) - 
            (Constants.apptNameRankingMap.get(persons.filter(x=> x.id === m2.personnel)[0].apptName) ?? 0)
          )
          .map((a) => {
            if (a.attid) {
              return (
                <tr key={a.attid}>
                  {/* <td>{a.attid}</td>
                  <td>{a.personnel}</td> */}
                  <td>
                    {persons.filter((p) => p.id === a.personnel)[0].apptName}
                  </td>
                  <td>
                    {persons.filter((p) => p.id === a.personnel)[0].rankName}
                  </td>
                  <td>
                    <Link to={`/admin/person/${a.personnel}/att/${a.attid}`}>
                      {persons.filter((p) => p.id === a.personnel)[0].name}
                    </Link>
                  </td>
                  <td>
                    {statuss.filter((s) => s.value === a.amstatus)[0].label}
                  </td>
                  <td>
                    {statuss.filter((s) => s.value === a.pmstatus)[0].label}
                  </td>
                  <td>{a.remark}</td>
                  <td>
                    <Link to={"/admin/leave/" + a.personnel}>Leave</Link>
                  </td>
                  <td>{a.submittedby}</td>
                </tr>
              );
            }
            return <></>;
          })}
        </tbody>
      </table>
    </>
  )
}

export default ParadeStateSubmitted;