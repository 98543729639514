import { Link } from "react-router-dom";
import { Ppl } from "../model";

const joinArray = (a: any[]) => {
  const array = [];
  for (let b of a) {
    array.push(b.name);
  }
  return array.join(", ");
};

const SettingPersonelList = (props:{
  pers : Ppl[]
}) => {
  const {pers} = props;
  return (
    <>
      <h3>List of Personnel</h3>
      <table className="alluser">
        <thead>
          <tr>
            <th>id</th>
            <th>Appt</th>
            <th>Rank</th>
            <th>Name</th>
            <th>Location</th>
          </tr>
        </thead>
        <tbody>
          {pers.map((p) => {
            return (
              <tr key={p.id}>
                <td>{p.id}</td>
                <td>{p.appointment.apptName}</td>
                <td>{p.rank.rankName}</td>
                <td>
                  <Link to={`/admin/person/${p.id}`}>{p.name}</Link>
                </td>
                <td>{joinArray(p.location)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default SettingPersonelList;
