import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { LocObj, MC } from "../model";

const initialState: LocObj = {
  loaded: false,
  loc: [
    { id: 1, name: "dental_sdc" },
    { id: 2, name: "dental_NeeSoon" },
    { id: 3, name: "dental_Kranji" },
    { id: 4, name: "dental_Tekong" },
  ],
};

export const mcSlice = createSlice({
  name: "MC",
  initialState,
  reducers: {
    setMC: (state: LocObj, action: PayloadAction<MC[]>) => {
      state.loc = action.payload;
      return state;
    },
    setLoaded: (state: LocObj, action:PayloadAction<boolean>) =>{
      state.loaded = action.payload;
      return state;
    }
  },
});

export default mcSlice.reducer;
