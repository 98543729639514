import { GetAttByPidSidYr, PplGetResult } from "../model";
import { getAttmap } from "./DashBoardWeeklyAttList";
import { getToweekno, getWeekMap, getWeekNo } from "./DashBoardWeekly";
import { Constants } from "../Constants";
import { round } from "./StrengthTable";

const getalldateinrange = (start: Date, end: Date) => {
  const arr = [];
  let tmp: Date;
  for (const dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
    tmp = new Date(dt);
    if (!(tmp.getDay() === 0 || tmp.getDay() === 6)) arr.push(new Date(dt));
  }
  return arr;
};

const convertDatetoStr = (date: Date) => {
  const yr = (date || new Date()).getFullYear();
  const mth = (date || new Date()).getMonth() + 1;
  const day = (date || new Date()).getDate();
  return `${yr}-${mth < 10 ? "0" + mth : mth}-${day < 10 ? "0" + day : day}`;
};

const dateArray = (
  dateRange: Date[],
  ppl: PplGetResult[] | undefined,
  attMap: Map<string, GetAttByPidSidYr[]>,
  today: string
) => {
  const attArr: number[][][] = [
    [[], [], [], [], []],
    [[], [], [], [], []],
    [[], [], [], [], []],
    [[], [], [], [], []],
    [[], [], [], [], []],
  ];
  let n = 0;
  const len = dateRange.length;
  const tday = new Date(today);
  for (let row = 0; row < 5; row++) {
    let daystr = "";
    // as there is no fixed starting pt at r=0, c=0
    if (row === 0) {
      for (let col = dateRange[0].getDay() - 1; n < len && col < 5; col++) {
        daystr = convertDatetoStr(dateRange[n]);
        // enter the date for cell
        attArr[row][col].push(dateRange[n].getDate());
        // attendance for future date without any future attendance
        if (dateRange[n] > tday && !attMap.has(daystr)) {
          attArr[row][col].push(100);
          attArr[row][col].push(100);
        } 
        // attendance for current date
        else if (dateRange[n].getDate() === tday.getDate()) {
          let total = ppl?.length || 0;
          let amnotp =
            attMap
              .get(daystr)
              ?.filter((a) => a.amstatus.id !== Constants.STATUS_PRESENT)
              .length || 0;
          let pmnotp =
            attMap
              .get(daystr)
              ?.filter((a) => a.pmstatus.id !== Constants.STATUS_PRESENT)
              .length || 0;
          attArr[row][col].push(round(((total - amnotp) / total) * 100, 1));
          attArr[row][col].push(round(((total - pmnotp) / total) * 100, 1));
        } 
        // attendance for future date but with attendence
        else if (dateRange[n] > tday) {
          let total = ppl?.length || 0;
          let amnotp =
            attMap
              .get(daystr)
              ?.filter((a) => a.amstatus.id !== Constants.STATUS_PRESENT)
              .length || 0;
          let pmnotp =
            attMap
              .get(daystr)
              ?.filter((a) => a.pmstatus.id !== Constants.STATUS_PRESENT)
              .length || 0;
          attArr[row][col].push(round(((total - amnotp) / total) * 100, 1));
          attArr[row][col].push(round(((total - pmnotp) / total) * 100, 1));
        } 
        // past attendance
        else if (dateRange[n] < tday) {
          let total = attMap.get(daystr)?.length || 0;
          let amnotp =
            attMap
              .get(daystr)
              ?.filter((a) => a.amstatus.id !== Constants.STATUS_PRESENT)
              .length || 0;
          let pmnotp =
            attMap
              .get(daystr)
              ?.filter((a) => a.pmstatus.id !== Constants.STATUS_PRESENT)
              .length || 0;
          attArr[row][col].push(round(((total - amnotp) / total) * 100, 1));
          attArr[row][col].push(round(((total - pmnotp) / total) * 100, 1));
        }
        n++;
      }
      continue;
    }
    // second row onward
    for (let col = 0; n < len && col < 5; col++) {
      daystr = convertDatetoStr(dateRange[n]);
      attArr[row][col].push(dateRange[n].getDate());
      // attendance for future date without any future attendance
      if (dateRange[n] > tday && !attMap.has(daystr)) {
        attArr[row][col].push(100);
        attArr[row][col].push(100);
      } 
      // attendance for current date
      else if (dateRange[n].getDate() === tday.getDate()) {
        let total = ppl?.length || 0;
        let amnotp =
          attMap
            .get(daystr)
            ?.filter((a) => a.amstatus.id !== Constants.STATUS_PRESENT)
            .length || 0;
        let pmnotp =
          attMap
            .get(daystr)
            ?.filter((a) => a.pmstatus.id !== Constants.STATUS_PRESENT)
            .length || 0;
        attArr[row][col].push(round(((total - amnotp) / total) * 100, 1));
        attArr[row][col].push(round(((total - pmnotp) / total) * 100, 1));
        console.log("this is attmap at today", attMap);
        console.log("this is amnotp at today", amnotp);
      } 
      // attendance for future date but with attendence
      else if (dateRange[n] > tday) {
        let total = ppl?.length || 0;
        let amnotp =
          attMap
            .get(daystr)
            ?.filter((a) => a.amstatus.id !== Constants.STATUS_PRESENT)
            .length || 0;
        let pmnotp =
          attMap
            .get(daystr)
            ?.filter((a) => a.pmstatus.id !== Constants.STATUS_PRESENT)
            .length || 0;
        attArr[row][col].push(round(((total - amnotp) / total) * 100, 1));
        attArr[row][col].push(round(((total - pmnotp) / total) * 100, 1));
      } 
      // past attendance
      else if (dateRange[n] < tday) {
        let total = attMap.get(daystr)?.length || 0;
        let amnotp =
          attMap
            .get(daystr)
            ?.filter((a) => a.amstatus.id !== Constants.STATUS_PRESENT)
            .length || 0;
        let pmnotp =
          attMap
            .get(daystr)
            ?.filter((a) => a.pmstatus.id !== Constants.STATUS_PRESENT)
            .length || 0;
        attArr[row][col].push(round(((total - amnotp) / total) * 100, 1));
        attArr[row][col].push(round(((total - pmnotp) / total) * 100, 1));
      }
      n++;
    }
  }
  return attArr;
};

const addDayuntilWeekday = (d: Date) => {
  if (d.getDay() >= 1 && d.getDay() <= 5) return d;
  let n = 0;
  if (d.getDay() == 0) n = 1;
  else n = 2;
  const tmp = new Date(d);
  tmp.setTime(d.getTime() + n * 86400000);
  return tmp;
};

const DashBoardMonthlyList = (props: {
  today: Date,
  loc: number,
  pplinmc: Map<number, PplGetResult[]>,
  attM: GetAttByPidSidYr[],
  range: Date[],
  month:number,
  daysfunc: React.Dispatch<React.SetStateAction<string>>,
  tabfunc: React.Dispatch<React.SetStateAction<number>>
}) => {
  const { today, loc, pplinmc, attM, range, month, daysfunc, tabfunc } = props;
  const attMap = getAttmap(attM, loc);
  const fweek = getToweekno(
    getWeekMap,
    getWeekNo
  )(addDayuntilWeekday(range[0]));
  const ppl = pplinmc.get(loc);
  const attArr = dateArray(
    getalldateinrange(range[0], range[1]),
    ppl,
    attMap,
    convertDatetoStr(today)
  );

  const onClickDate=(info:number[], tabno:number)=>{
    if (info.length <=0) return;
    const yr = range[1].getFullYear();
    const mm = month+1<10?"0"+(month+1):month+1;
    const dd = info[0]<10?"0"+info[0]:info[0];
    daysfunc(`${yr}-${mm}-${dd}`);
    tabfunc(tabno);
  }

  return (

      <table className="copytable">
        <thead>
          <tr>
            <th></th>
            <th>Mon</th>
            <th>Tue</th>
            <th>Wed</th>
            <th>Thu</th>
            <th>Fri</th>
          </tr>
        </thead>
        <tbody>
          {attArr.map((a, i) => {
            return (
              <tr>
                <td>
                  week {fweek + i}
                  <br />
                  am
                  <br />
                  pm
                </td>
                {a.map((info) => {
                  return (
                    <td>
                      <span onClick={()=>onClickDate(info,0)}>
                        {info.length > 0 ? info[0] : ""}
                      </span>
                      <br />
                      <span
                        style={{
                          background:
                            !Number.isNaN(info[1]) && info[1] >= 50
                              ? Constants.COLORABOVE50
                              : Constants.COLORBELOW50,
                        }}
                      >
                        {info.length > 1 && !Number.isNaN(info[1])
                          ? info[1] + "%"
                          : ""}
                      </span>
                      <br />
                      <span
                        style={{
                          background:
                            !Number.isNaN(info[2]) && info[2] >= 50
                              ? Constants.COLORABOVE50
                              : Constants.COLORBELOW50,
                        }}
                      >
                        {info.length > 2 && !Number.isNaN(info[2])
                          ? info[2] + "%"
                          : ""}
                      </span>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
  
  );
};

export default DashBoardMonthlyList;
