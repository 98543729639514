import { useEffect, useState } from "react";
import { GetAttByPidSidYr, MC, PplGetResult } from "../model";
import { Constants } from "../Constants";
import { round } from "./StrengthTable";

const datetoString = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return (
    year +
    "-" +
    month.toString(10).padStart(2, "0") +
    "-" +
    day.toString(10).padStart(2, "0")
  );
};

const getDateRange = (range:Date[]) =>{
  const dateRange: string[] = [datetoString(range[0])];
  const start = new Date(range[0]);
  for (let i = 1; i < 5; i++) {
    start.setDate(start.getDate() + 1);
    dateRange.push(datetoString(start));
  }
  return dateRange;
}

export const getAttmap = (attW:GetAttByPidSidYr[], loc: number) =>{
  const attmap: Map<string, GetAttByPidSidYr[]> = new Map();
  const attinmc = attW.filter((a) => a.location.id === loc);
  for (let att of attinmc) {
    if (attmap.has(att.date)) attmap.get(att.date)?.push(att);
    else attmap.set(att.date, [att]);
  }
  return attmap;
}

const DashBoardWeeklyAttList = (props: {
  today: Date,
  loc: number,
  pplinmc: Map<number, PplGetResult[]>,
  attW: GetAttByPidSidYr[],
  range: Date[],
  daysfunc : React.Dispatch<React.SetStateAction<string>>,
  tabfunc : React.Dispatch<React.SetStateAction<number>>
}) => {
  const { today, loc, pplinmc, attW, range, daysfunc, tabfunc } = props;
  const [perc, setPerc] = useState<number[][]>([
    [0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0],
  ]);

  const dateRange = getDateRange(range);
  const attmap = getAttmap(attW, loc);

  console.log(attmap);
  useEffect(() => {
    const tmp: number[][] = [
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
    ];
    for (let idx = 0; idx < 5; idx++) {
      let dateidx = new Date(dateRange[idx]);
      dateidx.setHours(0, 0, 0);
      if (dateidx < today) {
        let total = attmap.get(dateRange[idx])?.length || 0;
        let amStrength =
          attmap
            .get(dateRange[idx])
            ?.filter((a) => a.amstatus.id === Constants.STATUS_PRESENT)
            .length || 0;
        let pmStrength =
          attmap
            .get(dateRange[idx])
            ?.filter((a) => a.pmstatus.id === Constants.STATUS_PRESENT)
            .length || 0;
        tmp[0][idx] = total !== 0 ? amStrength / total : 0;
        tmp[1][idx] = total !== 0 ? pmStrength / total : 0;
      }
      if (dateidx >= today) {
        let total = pplinmc.get(loc)?.length || 0;
        let amStrength =
          total -
          (attmap
            .get(dateRange[idx])
            ?.filter((a) => a.amstatus.id != Constants.STATUS_PRESENT).length ||
            0);
        let pmStrength =
          total -
          (attmap
            .get(dateRange[idx])
            ?.filter((a) => a.pmstatus.id != Constants.STATUS_PRESENT).length ||
            0);
        tmp[0][idx] = total !== 0 ? amStrength / total : 0;
        tmp[1][idx] = total !== 0 ? pmStrength / total : 0;
      }
    }
    console.log(tmp);
    setPerc(tmp);
  }, [attW]);

  const onClickDateHandler = (date:string, tab:number)=>{
    daysfunc(date);
    tabfunc(tab);
  }

  return (
    <table className="copytable">
      <thead>
        <tr>
          <th>am/pm</th>
          <th onClick={() => onClickDateHandler(dateRange[0],0)}>
            Mon <br /> {dateRange[0]}
          </th>
          <th onClick={() => onClickDateHandler(dateRange[1],0)}>
            Tue <br /> {dateRange[1]}
          </th>
          <th onClick={() => onClickDateHandler(dateRange[2],0)}>
            Wed <br /> {dateRange[2]}
          </th>
          <th onClick={() => onClickDateHandler(dateRange[3],0)}>
            Thu <br /> {dateRange[3]}
          </th>
          <th onClick={() => onClickDateHandler(dateRange[4],0)}>
            Fri <br /> {dateRange[4]}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>am</td>
          {perc[0].map((x) => (
            <td
              style={{
                background: x >= 0.5 ? Constants.COLORABOVE50 : Constants.COLORBELOW50,
              }}
            >
              {round(x * 100, 1)}%
            </td>
          ))}
        </tr>
        <tr>
          <td>pm</td>
          {perc[1].map((x) => (
            <td
              style={{
                background: x >= 0.5 ? Constants.COLORABOVE50 : Constants.COLORBELOW50,
              }}
            >
              {round(x * 100, 1)}%
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  );
};
// round((amStrength / attendances.length) * 100, 1)%
export default DashBoardWeeklyAttList;
