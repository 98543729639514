import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Status } from "../model";

const initialState: Status[] = [
  {
    id: "1",
    state: "Present",
  },
  {
    id: "2",
    state: "Leave(AL)",
  },
  {
    id: "3",
    state: "Leave(OL)",
  },
  {
    id: "4",
    state: "MC",
  },
  {
    id: "5",
    state: "MA",
  },
  {
    id: "6",
    state: "HL",
  },
  {
    id: "7",
    state: "Off",
  },
  {
    id: "8",
    state: "OC",
  },
  {
    id: "9",
    state: "OutStation",
  },
  {
    id: "10",
    state: "Other",
  },
];

export const statusSlice = createSlice({
  name: "status",
  initialState,
  reducers: {
    setStatus: (state: Status[], action: PayloadAction<Status[]>) => {
      state = action.payload;
      return state;
    },
  },
});

export default statusSlice.reducer;
