import { useSelector } from "react-redux";
import { TokenAuth } from "../model";
import { RootState } from "../store/indexStore";
import { useState } from "react";
import { AppBar, Button, Container, IconButton, Toolbar } from "@mui/material";
import { Link } from "react-router-dom";
import "./header.css"

const HeaderBar: React.FC<{ htitle: string }> = (props) => {
  const loggedStatus: TokenAuth = useSelector(
    (state: RootState) => state.admin
  );

  return (
    <AppBar position="sticky" sx={{ bgcolor: "white", flexGrow: 1 }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <h1 className="headerH1">{props.htitle}</h1>
          {loggedStatus.isLoggedIn && (
            <Link to="/admin/setting">
              <Button variant="text">Setting</Button>
            </Link>
          )}
          {loggedStatus.isLoggedIn && loggedStatus.role === "super_admin" && (
            <Link to="/admin/dashboard">
              <Button variant="text">DashBoard</Button>
            </Link>
          )}
          {loggedStatus.isLoggedIn && (
            <Link to="/admin/paradestate">
              <Button variant="text">Parade State</Button>
            </Link>
          )}
          {loggedStatus.isLoggedIn && (
            <Link to="/admin/leavelist">
              <Button variant="text">LeaveList</Button>
            </Link>
          )}
          {loggedStatus.isLoggedIn && (
            <Link to="/admin/feedback">
              <Button variant="text">Feedback</Button>
            </Link>
          )}
          <span className="spacerAdminHeader"> </span>

          {loggedStatus.isLoggedIn && (
            <span className="admininfo">
              {loggedStatus.username}
              <br />
              {loggedStatus.role}
              <br />
              {loggedStatus.location}
            </span>
          )}
          {loggedStatus.isLoggedIn && (
            <Link to={"/logout"}>
              <Button variant="contained">Logout</Button>
            </Link>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default HeaderBar;
