import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  AdmRole,
  AdminInfo,
  MC,
  Pappt,
  Prank,
  Result,
  TokenAuth,
  Ppl,
  selectOpt,
  S_Event,
} from "../model";
import { useSelector } from "react-redux";
import store, { RootState } from "../store/indexStore";
import { Constants } from "../Constants";
import "./Setting.css";
import SettingChangePw from "./SettingChangePw";
import SettingCreateAdmin from "./SettingCreateAdmin";
import SettingAdminList from "./SettingAdminList";
import SettingCreatePersonnel from "./SettingCreatePersonnel";
import SettingPersonelList from "./SettingPersonelList";
import SettingCreateEvent from "./SettingCreateEvent";
import SettingEventList from "./SettingEventList";
import SettingCreateHoliday from "./SettingCreatePubHoliday";
import SettingHolidayList from "./SettingHolidayList";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";

// add request interceptor to add authorization header
axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("psau");
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});


const Setting = () => {
  const adminDefault: TokenAuth = useSelector(
    (state: RootState) => state.admin
  );
  const defaultAdmin: AdminInfo = {
    id: adminDefault.id || -1,
    username: adminDefault.username || "",
    auth: { role: adminDefault.role || "" },
    location: { name: adminDefault.location || "" },
    remark: "",
  };

  const [allAdminInfo, setAllAdminInfo] = useState<AdminInfo[]>([defaultAdmin]);
  const [pers, setPers] = useState<Ppl[]>([]);
  const [tabIndex, setTabIndex] = useState(0);
  // loading MC, Appt and Ranks from redux
  const locations: MC[] = useSelector((state:RootState) => state.mc).loc;
  const ranks : Prank[] = useSelector((state:RootState) => state.rank);
  const appts : Pappt[] = useSelector((state:RootState) => state.appt);

  // create new admin
  const [admRoles, setAdmRoles] = useState<AdmRole[]>([
    { role: "admin", id: 2 },
  ]);
  // all event
  const [events, setEvents] = useState<S_Event[]>([]);

  useEffect(() => {
    if (defaultAdmin.auth.role === "super_admin") {
      axios.get<AdminInfo[]>(Constants.URL_ADMINS).then((res) => {
        setAllAdminInfo(res.data);
      });
      axios.get<AdmRole[]>(Constants.URL_ROLES).then((res) => {
        setAdmRoles(res.data);
      });
      axios.get<Ppl[]>(Constants.URL_PERSON).then((res) => {
        setPers(res.data);
      });
      axios.get<S_Event[]>(Constants.URL_EVENT_ALL).then((res) => {
        setEvents(res.data);
      });
    }
  }, []);

  const locationOpt: selectOpt[] = [];
  locations.forEach((e) => locationOpt.push({ value: e.id, label: e.name }));

  const rankOpt: selectOpt[] = [];
  ranks.forEach((e) => rankOpt.push({ value: e.id, label: e.rankName }));

  const apptOpt: selectOpt[] = [];
  appts.forEach((e) => apptOpt.push({ value: e.id, label: e.apptName }));

  return (
    <div>
      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList>
          {adminDefault.role === "super_admin" && <Tab>Personnel</Tab>}
          {adminDefault.role === "super_admin" && <Tab>Admin</Tab>}
          {adminDefault.role === "super_admin" && <Tab>Event</Tab>}
          <Tab>Password</Tab>
        </TabList>
        {/* personnel tab */}
        {adminDefault.role === "super_admin" && <TabPanel>
          <SettingCreatePersonnel
              setPers={setPers}
              locationOpt={locationOpt}
              rankOpt={rankOpt}
              apptOpt={apptOpt}
            />
          <SettingPersonelList pers={pers} />
        </TabPanel>}
        {/* admin tab */}
        {adminDefault.role === "super_admin" && <TabPanel>
          <SettingCreateAdmin
                locations={locations}
                setAllAdminInfo={setAllAdminInfo}
          />
          <SettingAdminList
            adminDefault={adminDefault}
            setAllAdminInfo={setAllAdminInfo}
            allAdminInfo={allAdminInfo}
          />
        </TabPanel>}
        {/* event tab */}
        {adminDefault.role === "super_admin" && <TabPanel>
          <SettingCreateEvent setEvents={setEvents} />
          <SettingEventList events={events} setEvents={setEvents} />
          <br />
          <SettingCreateHoliday  />
          <SettingHolidayList/>
        </TabPanel>}

        <TabPanel>
          <SettingChangePw />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default Setting;
