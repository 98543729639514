import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TokenAuth } from "../model";

const initialState : TokenAuth = {
  id: -1,
  username: "",
  role: "",
  isLoggedIn: false,
  location: "",
  locid:-1
}

export const adminSlice = createSlice({
  name : "admin",
  // initialState: {isLoggedIn:false},
  initialState,
  reducers: {
    setToken : (state:TokenAuth, action:PayloadAction<TokenAuth>) =>{
      state = action.payload
      return state;
    }
  }
}) 

export default adminSlice.reducer;