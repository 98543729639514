import React, { useEffect } from "react";
import "./Logout.css";
import { useNavigate } from "react-router-dom";
import store from "../store/indexStore";
import { adminSlice } from "../store/admin";


const LogOut = () => {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      localStorage.removeItem("psau");
      store.dispatch(
        adminSlice.actions.setToken({
          username: undefined,
          role: undefined,
          isLoggedIn: false,
          id: undefined,
        })
      );
      navigate("/");
    }, 2000);
  }, []);

  return <div className="logoutcontainer">Logging out!!!</div>;
};

export default LogOut;
