import { useEffect, useState } from "react";
import { GetAttByPidSidYr } from "../model";
import axios from "axios";
import { Constants } from "../Constants";
import AttTable from "./AttTable";
import "react-tabs/style/react-tabs.css";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

const LeaveList = () => {
  const [leaveList, setLeaveList] = useState<GetAttByPidSidYr[]>([]);
  const d = new Date();
  const todayDateStr = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
  useEffect(() => {
    axios
      .get(Constants.URL_ATTENDANCE_ALL, { params: { date: todayDateStr } })
      .then((res) => {
        setLeaveList(res.data);
      })
      .catch((err) => console.log(err));
  }, []);
  const east: GetAttByPidSidYr[] = [];
  const west: GetAttByPidSidYr[] = [];
  const arr = ["0", "w", "e", "e", "e", "e", "e", "e", "w", "w"];
  for (let a of leaveList) {
    if (arr[a.location.id] === "e") east.push(a);
    else west.push(a);
  }

  return (
    <Tabs>
      <TabList>
        <Tab>East</Tab>
        <Tab>West</Tab>
      </TabList>
      <TabPanel>
        <h3>East Cluster</h3>
        <AttTable attlist={east} max={Constants.EASTLIMIT} />
      </TabPanel>
      <TabPanel>
        <h3>West Cluster</h3>
        <AttTable attlist={west} max={Constants.WESTLIMIT}/>
      </TabPanel>
    </Tabs>
  );
};

export default LeaveList;
